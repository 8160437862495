<template>
  <div class="px-4 w-full max-w-[793px] mx-auto lg:mt-10">
    <div v-if="notReady">
      <div
        v-if="fetching"
        class="flex justify-center items-center h-[50vh] w-full lg:w-[40vw]"
      >
        <spinner class="w-22 h-22" color="oneGreen" />
      </div>

      <template v-else>
        <BvnVerified
          v-if="verified"
          :bvn="bvnData"
          :showValid="showValid"
          @verified="updateBvn"
        />

        <ChooseForm
          v-else-if="!verified && canSkipBvn && !formOption"
          v-model="formOption"
        />

        <BvnForm
          v-else-if="showBvn"
          :verifying="verifying"
          @verify="verifyBvn"
          :pending="nibbsGone"
        />

        <NinForm
          v-else-if="showNin"
          :verifying="verifying"
          @verify="verifyBvn"
          :pending="nibbsGone"
        />
      </template>
    </div>
    <div v-else>
      <div class="border py-3 text-center rounded font-bold">
        <p class="text-lg">BVN Service Unavailable</p>
      </div>

      <div
        class="border mt-8 bg-[#F9F5E8] p-5 text-center rounded font-mulish flex item-center justify-between"
      >
        <div class="flex justify-center items-center mr-3 w-[100px]">
          <img
            class="w-[50px] h-[50px]"
            src="@/assets/images/icons/alert.svg"
            alt=""
          />
        </div>
        <div class="w-full text-left">
          You will be notified when service comes back to enable you complete
          your verification.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BvnVerified from "@/components/staff/verification/bvn/BvnVerified.vue";
import ChooseForm from "@/components/staff/verification/bvn/ChooseForm.vue";
import BvnForm from "@/components/staff/verification/bvn/BvnForm.vue";
import NinForm from "@/components/staff/verification/bvn/NinForm.vue";
export default {
  name: "BvnVerification",

  components: {
    BvnVerified,
    ChooseForm,
    BvnForm,
    NinForm,
  },

  data() {
    return {
      notReady: true,
      verified: false,
      canSkipBvn: false,
      fetching: false,
      verifying: false,
      nibbsGone: false,
      showValid: true,
      formOption: null,
      bvnData: {
        dobFailed: false,
        nameFailed: false,
        accountFailed: false,
        photograph: null,
        lastName: null,
        middleName: null,
        firstName: null,
        stateOfOrigin: null,
        dateOfBirth: null,
        bvnCode: null,
        gender: null,
        accountNumber: null,
      },
    };
  },

  async created() {
    await this.getEmployeeBvn();
  },

  computed: {
    showBvn() {
      return (!this.verified && !this.canSkipBvn) || this.formOption === "bvn";
    },
    showNin() {
      return this.formOption === "nin";
    },
  },

  methods: {
    async getEmployeeBvn() {
      try {
        this.fetching = true;

        const res = await this.$http.get("/employee/bvn");
        if (!res) {
          this.fetching = false;
          return;
        }

        const { data } = res;

        if (data.code === "E200") {
          this.nibbsGone = true;
          this.fetching = false;
          return;
        }

        this.canSkipBvn = data.canSkipBvn;
        if (data.data) {
          this.bvnData = { ...this.bvnData, ...data.data };
        }

        if (data.code === "S200") {
          this.verified = true;
          this.showValid = false;
        }

        if (data.code === "E403") {
          this.verified = true;
          this.bvnData = { ...this.bvnData, nameFailed: true };
        }

        this.fetching = false;
      } catch (error) {
        this.fetching = false;
      }
    },

    async verifyBvn() {
      try {
        this.verifying = true;

        const res = await this.$http.get("/bvn/verify");
        if (!res) {
          this.verifying = false;
          return;
        }

        console.log(res);

        const { data } = res;

        if (!data) {
          this.$swal({
            text: "BVN verification not successful please try again.",
            icon: "info",
          });
          this.$router.go();
          return;
        }

        if (data.code === "S404") {
          this.$swal({
            icon: "info",
            text: data.message,
          });
          this.verifying = false;
          return;
        }

        if (data.data) {
          this.bvnData = { ...this.bvnData, ...data.data };
          this.verified = true;
        }

        this.verifying = false;
      } catch (error) {
        this.verifying = false;
      }
    },

    updateBvn(d) {
      this.bvnData = { ...this.bvnData, ...d };
    },
  },
};
</script>

<style scoped></style>
