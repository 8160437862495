<template>
  <!-- Section 1 -->
  <div class="w-full m-auto lg:w-10/12">
    <div class="px-4 lg:px-16 mt-2.5">
      <label for="phone">
        Phone number
        <span class="text-red-400">*</span>
      </label>
      <input
        type="number"
        class="input"
        id="phone"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="080xxxxxxxx"
        v-model="form.phone"
      />
    </div>

    <div class="px-4 lg:px-20 mt-5">
      <label for="nin">
        National Identification Number (NIN)
        <span class="text-red-400">*</span>
      </label>
      <input
        type="number"
        class="input"
        id="nin"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Enter your NIN"
        v-model="form.nin"
      />
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="!stepOneReady || verifying"
        @click="confirmNin"
        class="bg-oneGreen text-white w-full text-center py-3 rounded font-semibold text-xl"
      >
        <spinner v-if="verifying" />
        <span>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NinStep1",

  data() {
    return {
      verifying: false,
      form: {
        nin: null,
        phone: null,
      },
      step: this.$store.getters["staff/step"],
    };
  },
  computed: {
    stepOneReady() {
      return this.ninReady && Boolean(this.form.phone);
    },
    ninReady() {
      return Boolean(this.form.nin) && this.form.nin.length === 11;
    },
  },
  methods: {
    // confirmNin() {
    //   this.saveData({ ...this.form });
    //   this.$router.push({ name: "NIN Step-2" });
    // },

    async confirmNin() {
      try {
        this.verifying = true;
        const res = await this.$http.post("/employee/verify", {
          phoneNumber: this.form.phone,
          nin: this.form.nin,
        });
        this.verifying = false;

        if (!res) {
          this.fetching = false;
          return;
        }

        const { data } = res;

        this.saveData({
          ...this.form,
          phoneNumber: data.phoneNumber,
          nin: data.nin,
        });

        this.$router.push({ name: "NIN Step-2" });
      } catch (error) {
        this.fetching = false;
        console.log(error);
      }
      this.saveData({ ...this.form });
      this.$router.push({ name: "NIN Step-2" });
    },

    saveData(data) {
      this.$store.commit("staff/updateNINData", data);
    },
  },
};
</script>
