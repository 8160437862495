<template>
  <div>
    <div v-if="fetching" class="flex justify-center items-center h-[60vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div v-else>
      <section class="text-idBlued">
        <h5>SECTION D:</h5>
        <h2>EMPLOYMENT DATA</h2>
      </section>

      <form @submit.prevent="proceed">
        <div>
          <label for="union">Union<span class="req">*</span> </label>
          <v-select
            id="union"
            label="name"
            :reduce="(b) => b.name"
            :options="$store.state.staff.unions"
            v-model="form.unionName"
            uid="_id"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div>
          <label for="unionNumber"> Union Membership Number </label>
          <input
            id="unionNumber"
            type="text"
            v-model="form.unionNumber"
            class="input"
          />
        </div>

        <div>
          <label for="employmentStatus">
            Employment Status<span class="req">*</span>
          </label>
          <v-select
            id="employmentStatus"
            label="label"
            :reduce="(b) => b.value"
            :options="employmentStatuses"
            v-model="form.employmentStatus"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div>
          <label for="dateOfAppointment">
            Date of 1st Appointment<span class="req">*</span>
          </label>
          <input
            id="dateOfAppointment"
            type="date"
            readonly
            v-model="form.dateOfAppointment"
            class="input"
          />
        </div>

        <div>
          <label for="lastPromotion">
            Date of Last Promotion<span class="req">*</span>
          </label>
          <input
            id="lastPromotion"
            type="date"
            v-model="form.lastPromotion"
            class="input"
          />
        </div>

        <!-- <div>
          <label for="pensionNumber"
            >Pension No<span class="req">*</span>
          </label>
          <input
            id="pensionNumber"
            type="text"
            v-model="form.pensionNumber"
            class="input"
          />
        </div> -->

        <div>
          <label for="pfa">Preferred PFA</label>
          <v-select
            id="pfa"
            label="pfaName"
            :reduce="(b) => b.pfaName"
            :options="$store.state.staff.pfas"
            v-model="form.pfa"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div>
          <label for="cadre">Cadre<span class="req">*</span> </label>
          <v-select
            id="cadre"
            label="cadreName"
            :reduce="(b) => b.cadreCode"
            :options="$store.state.staff.cadres"
            v-model="form.cadre"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div>
          <label for="confirmationDate">
            Confirmation Date<span class="req">*</span>
          </label>
          <input
            id="confirmationDate"
            type="date"
            v-model="form.confirmationDate"
            class="input"
          />
        </div>
        <div>
          <label for="salaryGradeLevel"
            >Salary Grade Level<span class="req">*</span>
          </label>
          <input
            id="salaryGradeLevel"
            type="text"
            readonly
            v-model="form.salaryGradeLevel"
            class="input"
          />
        </div>
        <div>
          <label for="step">Step<span class="req">*</span> </label>
          <input
            id="step"
            type="text"
            readonly
            v-model="form.step"
            class="input"
          />
        </div>

        <div>
          <label for="secretFile">Secret File Number</label>
          <input
            id="secretFile"
            type="text"
            v-model="form.secretFile"
            class="input"
          />
        </div>
        <div>
          <label for="mdaPresent">
            Present Duty Post<span class="req">*</span>
          </label>
          <v-select
            id="mdaPresent"
            label="name"
            :reduce="(b) => b.presentDuty"
            :options="$store.state.staff.mdas"
            v-model="form.presentDuty"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div>
          <label for="mdaCode">
            Parent MDA/LGC/LGEA/LGHA
            <span class="req">*</span>
          </label>
          <v-select
            id="mdaCode"
            label="name"
            :reduce="(b) => b.mdaCode"
            :options="$store.state.staff.mdas"
            v-model="form.mdaCode"
            placeholder="-- select --"
          ></v-select>

          <small v-if="mdaChanged" class="text-red-700 text-xs font-bold">
            &#x26A0; Your MDA has changed, but the update will be sent for
            approval. You can revert your change to prevent this action
          </small>
        </div>

        <div>
          <label for="departmentCode">
            Department<span class="req">*</span>
          </label>
          <v-select
            id="departmentCode"
            label="departmentName"
            :reduce="(b) => b.departmentCode"
            :options="departments"
            v-model="form.departmentCode"
            :filterable="false"
            @search="searchDepartments"
            placeholder="-- select departments --"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No results found for <em>{{ search }}</em
                >.
              </template>
              <span v-else clas="text-gray-400 lowercase text-xs">
                Start typing to search for your department
              </span>
            </template>
          </v-select>

          <small v-if="dptChanged" class="text-red-700 text-xs font-bold">
            &#x26A0; Your department has changed, but the update will be sent
            for approval. You can revert your change to prevent this action
          </small>
        </div>

        <div>
          <label for="dateOfPresentConfirmation">
            Date of Present Confirmation
          </label>
          <input
            id="dateOfPresentConfirmation"
            type="date"
            v-model="form.dateOfPresentConfirmation"
            class="input"
          />
        </div>

        <div>
          <label for="designation"
            >Designation<span class="req">*</span>
          </label>
          <v-select
            id="designation"
            label="designation"
            :reduce="(b) => b.designation"
            :options="$store.state.staff.designations"
            v-model="form.designation"
            uid="_id"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div v-if="!$store.getters['staff/stepStatus'](3)">
          <button :disabled="proceeding || !formReady" type="submit">
            <span>Save and Proceed</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { fetchDepartments } from "@/services/sourceData";

export default {
  name: "EmploymentData",

  data() {
    return {
      proceeding: false,
      fetching: false,
      form: {
        unionNumber: this.$store.state.staff.empInfo.unionNumber,
        employmentStatus: this.$store.state.staff.empInfo.employmentStatus,
        dateOfAppointment: this.formatDate(
          this.$store.state.staff.empInfo.dateOfAppointment
        ),
        dateOfRetirement: this.formatDate(
          this.$store.state.staff.empInfo.dateOfRetirement
        ),
        unionName: this.$store.state.staff.empInfo.unionName,
        presentDuty: this.$store.state.staff.empInfo.presentDuty,
        pensionNumber: this.$store.state.staff.empInfo.pensionNumber,
        pfa: this.$store.state.staff.empInfo.pfa,
        cadre: this.$store.state.staff.empInfo.cadre,
        confirmationDate: this.formatDate(
          this.$store.state.staff.empInfo.confirmationDate
        ),
        lastPromotion: this.$store.state.staff.empInfo.lastPromotion,
        salaryGradeLevel: this.$store.state.staff.empInfo.salaryGradeLevel,
        step: this.$store.state.staff.empInfo.step,
        secretFile: this.$store.state.staff.empInfo.secretFile,
        mdaCode: this.$store.state.staff.empInfo.mdaCode,
        departmentCode: this.$store.state.staff.empInfo.departmentCode,
        dateOfPresentConfirmation: this.formatDate(
          this.$store.state.staff.empInfo.dateOfPresentConfirmation
        ),
        nationalIdNumber: this.$store.state.staff.empInfo.nationalIdNumber,
        designation: this.$store.state.staff.empInfo.designation,
      },
      // "unionNumber",
      requiredForm: [
        "employmentStatus",
        "dateOfAppointment",
        "presentDuty",
        "cadre",
        "confirmationDate",
        "salaryGradeLevel",
        "step",
        "lastPromotion",
        "unionName",
        "mdaCode",
        "departmentCode",
        "dateOfPresentConfirmation",
        "designation",
      ],
      employmentStatuses: [
        { label: "Confirmed", value: "confirmed" },
        { label: "Probation", value: "probation" },
        { label: "Acting Appointment", value: "acting appointment" },
        { label: "Contract", value: "contract" },
      ],
      mdaChanged: false,
      dptChanged: false,
      mdaReady: false,
      departments: [],
    };
  },

  computed: {
    step() {
      return this.$store.getters["staff/step"];
    },
    formReady() {
      let ready = true;
      for (const fd of this.requiredForm) {
        if (this.form[fd] === null || this.form[fd] === "") {
          ready = false;
        }
      }
      return ready;
    },
  },

  watch: {
    "form.mdaCode"(nV) {
      this.mdaReady = false;
      const md = this.$store.state.staff.empInfo.mdaCode;
      if (md && nV !== md) {
        this.mdaChanged = true;
      } else {
        this.mdaChanged = false;
      }
    },
    "form.departmentCode"(nV) {
      const md = this.$store.state.staff.empInfo.departmentCode;
      if (md && nV !== md) {
        this.dptChanged = true;
      } else {
        this.dptChanged = false;
      }
    },
  },

  methods: {
    formatDate(dt) {
      return !dt ? dt : new Date(dt).toISOString().substring(0, 10);
    },

    async searchDepartments(search, loading) {
      if (search.length < 3) {
        return;
      }

      try {
        loading(true);
        this.departments = await fetchDepartments(search);
        loading(false);
      } catch (err) {
        loading(false);
      }
    },

    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }
      try {
        this.proceeding = true;

        const res = await this.$http.patch(
          "/employee/employment-info",
          this.form
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("staff/updateData", {
          data: data.data,
          key: "empInfo",
        });
        this.$store.commit("staff/updateVerifyStep", 4);
        this.$store.commit("staff/updateBStep", 5);

        this.$router.push({ name: "Staff Section E" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
