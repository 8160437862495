<template>
  <div class="w-full m-auto lg:w-10/12">
    <div class="px-4 lg:px-16 mt-5">
      <label for="confirmNin">
        Re-enter your National Identification Number (NIN)
        <span class="text-red-400">*</span>
      </label>
      <input
        type="number"
        class="input"
        id="confirmNin"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Re-enter your NIN"
        v-model="form.confirmNin"
      />
      <small class="text-xs text-red-700" v-if="ninNotEqual">
        Your NIN does not match
      </small>
    </div>

    <div class="flex justify-between gap-8 px-4 lg:px-20 mt-14 pb-3">
      <button
        @click="prev"
        class="border-oneGreen border text-oneGreen w-full text-center py-3 rounded font-semibold text-xl"
      >
        Back
      </button>

      <button
        :disabled="verifying || !stepTwoReady"
        @click="verifyNin"
        class="bg-[green] flex gap-2 justify-center text-white w-full text-center py-3 rounded font-semibold text-xl"
      >
        <spinner v-if="verifying" />
        <span>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NinStep2",

  data() {
    return {
      verifying: false,
      form: {
        confirmNin: null,
      },
    };
  },
  computed: {
    stepTwoReady() {
      return (
        this.$store.getters["staff/ninPayload"].nin === this.form.confirmNin
      );
    },
    ninNotEqual() {
      return (
        Boolean(this.form.confirmNin) &&
        this.form.confirmNin.length === 11 &&
        this.$store.getters["staff/ninPayload"].nin !== this.form.confirmNin
      );
    },
  },
  methods: {
    prev() {
      this.$router.go(-1);
    },

    async verifyNin() {
      try {
        this.verifying = true;

        const ninData = this.$store.getters["staff/ninPayload"];

        const res = await this.$http.post("/employee/proceed", {
          phoneNumber: ninData.phone,
          nin: ninData.nin,
        });

        this.verifying = false;

        if (!res) {
          return;
        }

        this.$router.push({ name: "Staff Bvn Verification" });
      } catch (err) {
        this.verifying = false;
      }
    },

    // verifyNin() {
    //   try {
    //     this.verifying = true;
    //     this.$router.push({ name: "NIN Step-3" });
    //     this.verifying = false;
    //   } catch (err) {
    //     this.verifying = false;
    //   }
    // },
  },
};
</script>
