<template>
  <div id="page">
    <BioDataNav :vsteps="vsteps" />

    <button
      @click="goBack"
      class="absolute right-0 hidden lg:block py-2 px-3 text-xl bg-white border border-idBlued rounded"
    >
      <span class="text-idBlued">&#8592;</span>
    </button>

    <section id="holder" class="w-full">
      <div class="w-12/12 lg:11/12">
        <router-view></router-view>
      </div>
    </section>
  </div>
</template>

<script>
import BioDataNav from "@/components/staff/verification/BioDataNav.vue";
export default {
  name: "BioData",

  components: {
    BioDataNav,
  },

  data() {
    return {
      prevRoute: null,
      vsteps: [
        { stp: 1, link: "Staff Section A" },
        { stp: 2, link: "Staff Section B" },
        { stp: 3, link: "Staff Section C" },
        { stp: 4, link: "Staff Section D" },
        { stp: 5, link: "Staff Section E" },
      ],
    };
  },

  async created() {
    await this.$store.dispatch("staff/getTitles");
    await this.$store.dispatch("staff/getCountries");
    await this.$store.dispatch("staff/getStates");
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
#page {
  @apply mt-1 lg:mt-5 px-4 w-full max-w-[747px] mx-auto flex flex-col gap-7 lg:flex-row lg:items-start lg:gap-16 relative;
  height: calc(100vh - 160px);
}
section#holder {
  @apply h-auto overflow-y-scroll;
  height: calc(100vh - 200px);
}
button {
  z-index: 9;
}
</style>
