<template>
  <div class="p-10">
    <div
      v-if="fetching"
      class="flex justify-center items-center h-[50vh] w-[70vw]"
    >
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div v-else>
      <div v-if="showForm">
        <router-view></router-view>
      </div>

      <div v-else class="w-auto mx-auto lg:w-6/12">
        <section>
          <h3 class="font-bold text-xl lg:text-3pxl text-idBlued">
            NIN VERIFICATION
          </h3>
        </section>

        <section
          class="rounded-lg bg-litergray p-3.5 flex flex-col items-start lg:flex-row gap-4 lg:gap-8 w-full mt-5 lg:mt-7"
        >
          <div>
            <img
              :src="
                ninData.photograph
                  ? ninData.photograph
                  : require('@/assets/images/null_female.png')
              "
              alt="user picture"
              class="rounded-full h-18 w-18 object-cover lg:h-24 lg:w-24 border border-oneGreen"
            />
          </div>

          <table>
            <tr>
              <td>NIN:</td>
              <td>{{ ninData.nin }}</td>
            </tr>
            <tr>
              <td>Lastname:</td>
              <td>{{ profile.lastName }}</td>
            </tr>
            <tr>
              <td>Firstname:</td>
              <td>{{ profile.firstName }}</td>
            </tr>
            <tr>
              <td>Middlename:</td>
              <td>{{ profile.middleName }}</td>
            </tr>
            <tr>
              <td>Gender:</td>
              <td>{{ profile.gender }}</td>
            </tr>

            <tr>
              <td>Date of Birth:</td>
              <td>{{ dateOfBirth }}</td>
            </tr>
          </table>
        </section>

        <section
          class="bg-oneGreen/10 p-2.5 flex items-center gap-2.5 mt-2.5 rounded-lg"
        >
          <img
            src="@/assets/images/icons/shield.svg"
            alt="icons"
            class="w-8 h-8 lg:w-10 lg:h-10"
          />

          <p class="text-oneGreen font-medium text-xs lg:text-sm">
            NIN Verification Done
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NinVerification",
  data() {
    return {
      fetching: true,
      showForm: true,
      profile: {},
      step: this.$store.getters["staff/profile"].verificationStep,
    };
  },
  computed: {
    ninData() {
      return this.$store.getters["staff/ninPayload"]
        ? this.$store.getters["staff/ninPayload"]
        : false;
    },
    dateOfBirth() {
      return this.profile.dateOfBirth.split("T")[0];
    },
  },
  async created() {
    await this.fetchNIN();
    this.profile = this.$store.getters["staff/profile"];
    this.fetching = false;
  },
  methods: {
    async fetchNIN() {
      try {
        const res = await this.$http.get("/employee/nin");

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$store.commit("staff/saveData", {
          key: "ninPayload",
          data: null,
        });

        if (data.code === "E200") {
          this.showForm = true;
        } else {
          this.showForm = false;
          this.$store.commit("staff/saveData", {
            key: "ninPayload",
            data: { ...this.ninData, nin: data.data.nin },
          });
        }
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped>
table {
  @apply w-full lg:w-11/12;
}
table tr td {
  @apply text-xs lg:text-base text-dkgray py-1;
}
table tr td:first-child {
  @apply font-bold w-2/5;
}
table tr td:last-child {
  @apply font-medium w-3/5;
}
</style>
