<template>
  <div>
    <div v-if="fetching" class="flex justify-center items-center h-[60vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div v-else>
      <section class="text-idBlued">
        <h5>SECTION C:</h5>
        <h2>EDUCATION</h2>
      </section>

      <small class="text-purple-600 font-bold">
        Start with your highest academic degree
      </small>
      <form @submit.prevent="proceed">
        <!-- Institutions start -->
        <template>
          <fieldset v-for="(edu, i) in educations" :key="i" class="border">
            <legend class="font-bold lg:text-xl text-idBlued">
              Academic Institution #{{ i + 1 }}
            </legend>

            <div>
              <label :for="`qualification${i}`">
                Institution Level <span class="req">*</span>
              </label>
              <v-select
                :id="`qualification${i}`"
                label="academyName"
                :reduce="(b) => b.academyName"
                :options="$store.state.staff.academicCategories"
                placeholder="-- select --"
                v-model="edu.qualification"
                @input="getDegree"
              >
              </v-select>
            </div>

            <div>
              <label :for="`institutionName${i}`">
                Institution Name <span class="req">*</span>
              </label>
              <input
                type="text"
                class="input"
                :id="`institutionName${i}`"
                v-uppercase
                v-model="edu.institutionName"
              />
            </div>

            <div>
              <label :for="`degree${i}`">
                Academic Degree <span class="req">*</span>
              </label>
              <v-select
                :id="`degree${i}`"
                label="degreeName"
                :reduce="(b) => b.degreeName"
                :options="academicDegrees"
                placeholder="-- select --"
                v-model="edu.degree"
                :disabled="!edu.qualification"
                @input="getAcademicQualification"
              >
              </v-select>
            </div>

            <div>
              <label :for="`academicQualification${i}`">
                Academic Qualifications <span class="req">*</span>
              </label>
              <v-select
                :id="`academicQualification${i}`"
                label="qualificationName"
                :reduce="(b) => b.qualificationName"
                :options="academicQualifications"
                placeholder="-- select --"
                v-model="edu.academicQualification"
                :disabled="!edu.degree"
              >
              </v-select>
            </div>

            <div>
              <label :for="`grade${i}`">Grade<span class="req">*</span> </label>
              <v-select
                :id="`grade${i}`"
                v-uppercase
                v-model="edu.grade"
                :options="academicGrades"
                placeholder="select grade"
              />
            </div>

            <div>
              <label :for="`year${i}`">
                Year of graduation<span class="req">*</span>
              </label>
              <v-select
                :id="`year${i}`"
                :options="years"
                v-uppercase
                v-model="edu.year"
              ></v-select>
            </div>

            <div v-if="i > 0">
              <button
                v-if="canEdit"
                type="button"
                class="py-1 px-4 rounded-full bg-red-600 text-white"
                @click="removeEdu(i)"
              >
                remove
              </button>
            </div>
          </fieldset>

          <div class="flex justify-end mt-3">
            <button
              v-if="canEdit"
              type="button"
              class="px-5 lg:px-9 py-2 rounded-full bg-idBlued text-white"
              @click="addNewEdu"
            >
              + Add another Institution
            </button>
          </div>
        </template>
        <!-- Institutions end -->

        <fieldset class="mt-7">
          <p class="font-bold">
            Are you a member of any of these Professional Bodies below?
          </p>
          <p class="flex gap-5 mt-2">
            <label>
              <input type="radio" :value="true" v-model="hasProfBody" /> Yes
            </label>
            <label>
              <input type="radio" :value="false" v-model="hasProfBody" /> No
            </label>
          </p>
        </fieldset>

        <!-- Professional Bodies start -->
        <template v-if="hasProfBody">
          <fieldset
            v-for="(prof, x) in professionalBodies"
            :key="`prof${x}`"
            class="border"
          >
            <legend class="font-bold lg:text-xl text-idBlued">
              Professional Body #{{ x + 1 }}
            </legend>

            <div>
              <label :for="`name${x}`">
                Select the Professional Body <span class="req">*</span>
              </label>
              <v-select
                :id="`name${x}`"
                label="professionalBodyName"
                :reduce="(b) => b.professionalBodyName"
                :options="$store.state.staff.professionalBodies"
                placeholder="-- select --"
                v-model="prof.name"
              >
              </v-select>
            </div>

            <div>
              <label :for="`profYear${x}`">
                Which year did you become a member of the professional body?
                <span class="req">*</span>
              </label>
              <v-select
                :id="`profYear${x}`"
                :options="years"
                v-uppercase
                v-model="prof.year"
              ></v-select>
            </div>

            <div>
              <label :for="`membershipNumber${x}`">
                Membership Number
                <span class="req">*</span>
              </label>
              <input
                type="text"
                class="input"
                :id="`membershipNumber${x}`"
                v-uppercase
                v-model="prof.membershipNumber"
              />
            </div>

            <div class="relative">
              <label :for="`image${x}`">
                Please upload a certification or any evidence that proves your
                membership
                <span class="req">*</span>
              </label>
              <input
                type="file"
                class="w-full rounded-lg border border-litgray py-1.5 px-3"
                :id="`image${x}`"
                accept="application/pdf"
                :disabled="!prof.name || prof.uploading"
                @change="(e) => uploadDoc(e, prof)"
              />
              <p class="flex justify-between">
                <small class="text-idBlued">
                  <span v-if="!prof.image">
                    Accepted file type is <b>pdf</b>. Not more than 2mb
                  </span>
                  <a
                    v-else
                    :href="prof.image"
                    download=""
                    v-uppercase
                    class="underline"
                    >{{ prof.name }}.pdf</a
                  >
                </small>

                <small class="text-oneGreen font-semibold">
                  <spinner
                    v-if="prof.uploading"
                    color="gray-500"
                    class="w-4 h-4"
                    >Loading</spinner
                  >
                  <span v-if="prof.image">uploaded ✅</span>
                </small>
              </p>
            </div>

            <div v-if="x > 0">
              <button
                v-if="canEdit"
                type="button"
                class="py-1 px-4 rounded-full bg-red-600 text-white"
                @click="removeProf(x)"
              >
                remove
              </button>
            </div>
          </fieldset>

          <div class="flex justify-end mt-3">
            <button
              v-if="canEdit"
              type="button"
              class="px-5 lg:px-9 py-1.5 rounded-full bg-idBlued text-white"
              @click="addNewProf"
            >
              + Add another Profeessional Body
            </button>
          </div>
        </template>
        <!-- Professional Bodies end -->

        <fieldset v-if="canEdit">
          <div>
            <button :disabled="proceeding || !formReady" type="submit">
              <span>Save and Proceed</span>
              <spinner v-if="proceeding"></spinner>
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script src="https://sdk.amazonaws.com/js/aws-sdk-2.813.0.min.js"></script>
<script>
import {
  fetchAcademicDegrees,
  fetchAcademicQualification,
} from "@/services/sourceData";
import { AcademicGrades } from "@/services/staffProfile";
const emptyEdu = {
  qualification: null,
  institutionName: null,
  grade: null,
  year: null,
  degree: null,
  academicQualification: null,
};
const emptyProfBody = {
  name: null,
  membershipNumber: null,
  image: null,
  year: null,
  uploading: false,
};
export default {
  name: "Education",

  data() {
    return {
      fetching: true,
      proceeding: false,
      hasProfBody: true,
      educations:
        this.$store.state.staff.educations.length > 0
          ? this.$store.state.staff.educations
          : [{ ...emptyEdu }],
      professionalBodies:
        this.$store.state.staff.professionalBodiesInfo.length > 0
          ? this.$store.state.staff.professionalBodiesInfo
          : [{ ...emptyProfBody }],
      academicDegrees: [],
      academicQualifications: [],
      academicGrades: AcademicGrades,
    };
  },

  computed: {
    step() {
      return this.$store.getters["staff/step"];
    },
    years() {
      const years = [];
      let y = new Date().getFullYear();
      const stp = y - 70;
      for (; y >= stp; y--) {
        years.push(`${y}`);
      }
      return years;
    },
    formReady() {
      let isOk = true;
      for (const edu of this.educations) {
        for (const key in edu) {
          if (Object.hasOwnProperty.call(edu, key)) {
            if (!edu[key] || !edu[key].length || !edu[key].trim().length)
              isOk = false;
          }
        }
      }

      if (!this.hasProfBody) return isOk;

      for (const prof of this.professionalBodies) {
        for (const key in prof) {
          if (Object.hasOwnProperty.call(prof, key)) {
            if (!prof[key] || !prof[key].length || !prof[key].trim().length)
              isOk = false;
          }
        }
      }

      return isOk;
    },
    canEdit() {
      return !this.$store.getters["staff/stepStatus"](2);
    },
  },

  async created() {
    await this.$store.dispatch("staff/getAcademicCategories");
    await this.$store.dispatch("staff/getProfessionalBodies");
    // await this.$store.dispatch("staff/getProfessionalSkills");
    this.fetching = false;
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please Fill all fiields, or remove any not used section",
        });
        return;
      }
      try {
        this.proceeding = true;

        const res = await this.$http.patch("/employee/education", {
          education: this.educations,
          professionalBodies: this.hasProfBody ? this.professionalBodies : [],
        });

        this.proceeding = false;
        if (!res) {
          return;
        }

        // const { data } = res;

        this.$store.commit("staff/saveData", {
          data: this.educations,
          key: "educations",
        });

        this.$store.commit("staff/updateVerifyStep", 3);
        this.$store.commit("staff/updateBStep", 4);

        this.$router.push({ name: "Staff Section D" });
      } catch (error) {
        this.proceeding = false;
      }
    },
    async getDegree(name) {
      const academy = this.$store.state.staff.academicCategories.find(
        (acad) => acad.academyName === name
      );
      this.academicDegrees = await fetchAcademicDegrees(academy.academyCode);
    },
    async getAcademicQualification(name) {
      const degree = this.academicDegrees.find(
        (acad) => acad.degreeName === name
      );
      this.academicQualifications = await fetchAcademicQualification(
        degree.degreeCode
      );
    },
    addNewEdu() {
      this.educations.push({ ...emptyEdu });
    },
    removeEdu(i) {
      this.educations.splice(i, 1);
    },
    addNewProf() {
      this.professionalBodies.push({ ...emptyProfBody });
    },
    removeProf(i) {
      this.professionalBodies.splice(i, 1);
    },
    slugify(text) {
      return text
        .toString()
        .trim()
        .toUpperCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
        .replace(/\-\-+/g, "-"); // Replace multiple - with single -
    },
    async uploadDoc(e, prof) {
      AWS.config.region = this.AWS_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.AWS_IDENTITY_POOL,
      });

      const fileSizeLimit = 2 * 1024 ** 2; // 2MB

      let file = e.target.files[0];
      if (file.size > fileSizeLimit) {
        this.$swal({
          icon: "error",
          text: "The selected file is more than 2mb",
        });
        file = null;
        return;
      }

      const verificationCode =
        this.$store.state?.staff?.profile?.verificationCode;
      const profName = this.slugify(prof.name);
      const key = `professional-bodies/${verificationCode}_${profName}.pdf`;

      const params = {
        Bucket: this.AWS_BUCKET,
        Key: key,
        Body: file,
      };

      try {
        prof.uploading = true;
        const upload = new AWS.S3.ManagedUpload({ params });
        const resp = await upload.promise();

        prof.image = resp.Location;
        prof.uploading = false;
        delete prof.uploading;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
h5 {
  @apply text-xs lg:text-base font-bold uppercase;
}
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply mt-7;
}
fieldset {
  @apply bg-litergray rounded-lg p-6 mt-6;
}
fieldset > div:not(:first-child) {
  @apply mt-6;
}
fieldset h4 {
  @apply text-litgray font-medium text-2xl;
}
.req {
  @apply text-red-400;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
.input[type="text"] {
  font-size: 1rem;
  line-height: 1rem;
}
</style>
